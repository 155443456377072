@tailwind base;

@tailwind components;
/* DateLimit */

.limit {
    border-radius: 15px;
    background-color: #bef8be;
  }
  
  .limitAlarm {
    border-radius: 15px;
    background-color: #f8d8be;
  }
  
  .limitAlert {
    border-radius: 15px;
    background-color: #f8bebe;
  }
  
  /* Alert */
  .alert {
    background-color: var(--light-color);
    padding: 10px 20px;
    font-weight: bold;
    margin: 15px 0;
  }
  
  .alert i {
    margin-right: 10px;
  }
  
  .alert-success {
    background-color: var(--success-color);
    color: #fff;
  }
  
  .alert-error {
    background-color: var(--error-color);
    color: #fff;
  }

@tailwind utilities;