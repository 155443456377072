.popup {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height : 100vh;
    background-color: rgba(0,0,0,0.2);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-inner {
    position: relative;
    padding: 0px;
    width: 20%;
    max-width: 1200px;
    border: 2px rgb(105, 104, 104) solid;
    background-color: #FFF;
}

.popup-inner .close-btn {
    position: absolute;
    top: 6px;
    right: 10px;
}

.popup-inner-info .close-btn {
    position: absolute;
    top: 6px;
    right: 10px;
}

.popup-inner-info {
    position: relative;
    padding: 0px;
    width: 100%;
    max-width: 400px;
    border: 0px rgb(105, 104, 104) solid;
    border-radius: 20px;
    background-color: #FFFD;
}