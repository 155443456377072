* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
* :focus {
  border: 1px solid;
}

* :focus:not(:focus-visible) {
  outline: none !important;
  border: none !important;
}

html {
  width: 100%;
  height: 100%;
}

header.mainHeader {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  background: #ffffff;
}

body {
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 20px;
  -webkit-font-smoothing: antialiased;
  font-family: "Jost", sans-serif;
  font-weight: 400;
  color: #0f1e5a;
  background: #ffffff;
  width: 100%;
}

header.mainHeader .logo {
  width: calc((100vw / 24) * 2);
  text-align: center;
}

header.mainHeader .logo a:focus {
  border: 1px solid;
}

header.mainHeader .logo svg {
  width: 120px;
  height: auto;
  display: block;
  margin: 0 auto;
}

header.mainHeader .row-1 {
  width: calc((100vw / 24) * 12);
  padding-right: calc((100vw / 24) * 0.5);
  margin-top: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

header.mainHeader .megaMenu {
  width: calc((100vw / 24) * 14);
  /* padding-right:calc((100vw / 24) * 0.5); */
  margin: 5px 0;
}

header.mainHeader .megaMenu .row-2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-top: 1px solid #00000040;
}

header.mainHeader .megaMenu .row-2 ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

header.mainHeader .megaMenu .row-2 ul li.openFirstLevel a {
  font-family: "Jost", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
  color: #0f1e5a;
  display: block;
  padding-top: 15px;
  padding-bottom: 5px;
}

header.mainHeader .megaMenu .row-2 ul li.openFirstLevel {
  padding-left: 30px;
  position: relative;
}

header.mainHeader .row-1 ul.shortcuts li.switchLang button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #0f1e5a;
  width: 100%;
  height: 100%;
  position: relative;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  border-radius: 50%;
}

header.mainHeader .megaMenu .row-2 .mainLinks {
  display: flex;
  flex-direction: row;
}

ul li {
  list-style: none;
}

main.main {
  margin-top: 175px;
}
main.article {
  overflow: hidden;
  /* background: #eeeeee; */
}

/* header.mainHeader .megaMenu .row-2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-top: 1px solid #00000040;
  margin-top: 15px;
} */
/* header.mainHeader .megaMenu .row-2 ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
} */
header.mainHeader .megaMenu .row-2 ul li.openFirstLevel {
  padding-left: 30px;
  position: relative;
}
header.mainHeader .megaMenu .row-2 ul li.openFirstLevel:first-of-type {
  padding-left: 0;
}
header.mainHeader .megaMenu .row-2 ul li.openFirstLevel:first-of-type::after {
  left: 0;
}
header.mainHeader
  .megaMenu
  .row-2
  ul
  li.openFirstLevel:first-of-type:hover::after {
  width: 100%;
}
header.mainHeader .megaMenu .row-2 ul li.openFirstLevel::after {
  content: "";
  width: 0;
  height: 2px;
  background: #0f1e5a;
  position: absolute;
  top: -1px;
  left: 10px;
  -webkit-transition: 0.3s width ease-in-out;
  transition: 0.3s width ease-in-out;
}
header.mainHeader .megaMenu .row-2 ul li.openFirstLevel:hover::after {
  width: calc(100% - 30px)
}
header.mainHeader .megaMenu .row-2 ul li.openFirstLevel a {
  font-family: "Jost", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
  color: #0f1e5a;
  display: block;
  padding-top: 15px;
  padding-bottom: 5px;
}
header.mainHeader .megaMenu .row-2 ul li.openFirstLevel .subMenu.firstLevel {
  display: none;
  background: #ffffff;
  width: 100%;
  position: fixed;
  left: 0;
  top: 122px;
  text-align: left;
  z-index: 5;
  padding: 0;
  padding-top: 30px;
  min-height: 450px;
  overflow: hidden;
}
header.mainHeader
  .megaMenu
  .row-2
  ul
  li.openFirstLevel:hover
  .subMenu.firstLevel {
  display: block;
}
header.mainHeader
  .megaMenu
  .row-2
  ul
  li.openFirstLevel
  .subMenu.firstLevel
  .inner
  .column-1 {
  width: calc((100vw / 24) * 11);
}
header.mainHeader
  .megaMenu
  .row-2
  ul
  li.openFirstLevel
  .subMenu.firstLevel
  .inner
  .column-1
  .top {
  margin-bottom: 30px;
  margin-left: 30px;
}
header.mainHeader
  .megaMenu
  .row-2
  ul
  li.openFirstLevel
  .subMenu.firstLevel
  .inner
  .column-1
  .top
  a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
header.mainHeader
  .megaMenu
  .row-2
  ul
  li.openFirstLevel
  .subMenu.firstLevel
  .inner
  .column-1
  .top
  a
  span {
  margin-right: 10px;
  font-size: 29px;
  line-height: 30px;
  font-family: "Jost", sans-serif;
  font-weight: 600;
}
header.mainHeader
  .megaMenu
  .row-2
  ul
  li.openFirstLevel
  .subMenu.firstLevel
  .inner
  .column-1
  .top
  p {
  font-size: 14px;
  line-height: 20px;
}
header.mainHeader
  .megaMenu
  .row-2
  ul
  li.openFirstLevel
  .subMenu.firstLevel
  .inner
  .column-1
  ul {
  display: block;
  position: relative;
  width: calc((100vw / 24) * 4);
  padding-bottom: 30px;
  min-height: 350px;
}
header.mainHeader
  .megaMenu
  .row-2
  ul
  li.openFirstLevel
  .subMenu.firstLevel
  .inner
  .column-1
  ul
  li.active
  svg
  path {
  fill: #cc0000;
}
header.mainHeader
  .megaMenu
  .row-2
  ul
  li.openFirstLevel
  .subMenu.firstLevel
  .inner
  .column-1
  ul
  li:last-of-type {
  margin-bottom: 0;
}
header.mainHeader
  .megaMenu
  .row-2
  ul
  li.openFirstLevel
  .subMenu.firstLevel
  .inner
  .column-1
  ul
  li.active
  a::after {
  width: 100%;
}
header.mainHeader
  .megaMenu
  .row-2
  ul
  li.openFirstLevel
  .subMenu.firstLevel
  .inner
  .column-1
  ul
  li
  a,
header.mainHeader
  .megaMenu
  .row-2
  ul
  li.openFirstLevel
  .subMenu.firstLevel
  .inner
  .column-1
  ul
  li
  button {
  font-family: "Jost", sans-serif;
  font-weight: 400;
  font-size: 21px;
  line-height: 30px;
  text-transform: none;
  position: relative;
  display: inline-block;
  padding: 0 0 5px 0;
  color: #0f1e5a;
  border-radius: 25px;
  padding: 10px 30px;
  width: 100%;
  text-align: left;
}
header.mainHeader
  .megaMenu
  .row-2
  ul
  li.openFirstLevel
  .subMenu.firstLevel
  .inner
  .column-1
  ul
  li
  a.active,
header.mainHeader
  .megaMenu
  .row-2
  ul
  li.openFirstLevel
  .subMenu.firstLevel
  .inner
  .column-1
  ul
  li
  a:hover,
header.mainHeader
  .megaMenu
  .row-2
  ul
  li.openFirstLevel
  .subMenu.firstLevel
  .inner
  .column-1
  ul
  li
  button.active,
header.mainHeader
  .megaMenu
  .row-2
  ul
  li.openFirstLevel
  .subMenu.firstLevel
  .inner
  .column-1
  ul
  li
  button:hover {
  background: #f8f8f8;
}
header.mainHeader
  .megaMenu
  .row-2
  ul
  li.openFirstLevel
  .subMenu.firstLevel
  .inner
  .column-1
  ul
  li
  a.active
  svg
  path,
header.mainHeader
  .megaMenu
  .row-2
  ul
  li.openFirstLevel
  .subMenu.firstLevel
  .inner
  .column-1
  ul
  li
  a:hover
  svg
  path,
header.mainHeader
  .megaMenu
  .row-2
  ul
  li.openFirstLevel
  .subMenu.firstLevel
  .inner
  .column-1
  ul
  li
  button.active
  svg
  path,
header.mainHeader
  .megaMenu
  .row-2
  ul
  li.openFirstLevel
  .subMenu.firstLevel
  .inner
  .column-1
  ul
  li
  button:hover
  svg
  path {
  fill: #cc0000;
}
header.mainHeader
  .megaMenu
  .row-2
  ul
  li.openFirstLevel
  .subMenu.firstLevel
  .inner
  .column-1
  ul
  li
  a
  svg,
header.mainHeader
  .megaMenu
  .row-2
  ul
  li.openFirstLevel
  .subMenu.firstLevel
  .inner
  .column-1
  ul
  li
  button
  svg {
  margin-left: 5px;
  width: 18px;
}
header.mainHeader
  .megaMenu
  .row-2
  ul
  li.openFirstLevel
  .subMenu.firstLevel
  .inner
  .column-1
  ul
  li
  button {
  background: none;
  border: none;
  cursor: pointer;
}
header.mainHeader
  .megaMenu
  .row-2
  ul
  li.openFirstLevel
  .subMenu.firstLevel
  .inner
  .column-1
  ul
  li
  .subMenu.secondLevel {
  background: #f8f8f8;
  left: 100%;
  min-height: 100%;
  border-radius: 0px 20px 0px 0px;
  padding-top: 20px;
  padding-bottom: 20px;
  position: absolute;
  top: -20px;
  display: none;
}
header.mainHeader
  .megaMenu
  .row-2
  ul
  li.openFirstLevel
  .subMenu.firstLevel
  .inner
  .column-1
  ul
  li
  .subMenu.secondLevel
  ul {
  width: calc((100vw / 24) * 4);
}
header.mainHeader
  .megaMenu
  .row-2
  ul
  li.openFirstLevel
  .subMenu.firstLevel
  .inner
  .column-1
  ul
  li
  .subMenu.secondLevel
  ul
  li:last-of-type {
  margin-bottom: 0;
}
header.mainHeader
  .megaMenu
  .row-2
  ul
  li.openFirstLevel
  .subMenu.firstLevel
  .inner
  .column-1
  ul
  li
  .subMenu.secondLevel
  ul
  li
  a,
header.mainHeader
  .megaMenu
  .row-2
  ul
  li.openFirstLevel
  .subMenu.firstLevel
  .inner
  .column-1
  ul
  li
  .subMenu.secondLevel
  ul
  li
  button {
  font-size: 21px;
  line-height: 30px;
  color: #0f1e5a;
}
header.mainHeader
  .megaMenu
  .row-2
  ul
  li.openFirstLevel
  .subMenu.firstLevel
  .inner
  .column-1
  ul
  li
  .subMenu.secondLevel
  ul
  li
  a:hover,
header.mainHeader
  .megaMenu
  .row-2
  ul
  li.openFirstLevel
  .subMenu.firstLevel
  .inner
  .column-1
  ul
  li
  .subMenu.secondLevel
  ul
  li
  button:hover {
  background: #eaeef1;
  text-decoration: underline;
}
header.mainHeader
  .megaMenu
  .row-2
  ul
  li.openFirstLevel
  .subMenu.firstLevel
  .inner
  .column-2 {
  width: calc((100vw / 24) * 5.5);
  background: #eeeeee;
  border-radius: 10px 0px 0px 0px;
  padding: 30px 30px 30px 0;
}
header.mainHeader
  .megaMenu
  .row-2
  ul
  li.openFirstLevel
  .subMenu.firstLevel
  .inner
  .column-2
  ul {
  display: block;
  margin-left: -45px;
}
header.mainHeader
  .megaMenu
  .row-2
  ul
  li.openFirstLevel
  .subMenu.firstLevel
  .inner
  .column-2
  ul
  li {
  margin-bottom: 30px;
}
header.mainHeader
  .megaMenu
  .row-2
  ul
  li.openFirstLevel
  .subMenu.firstLevel
  .inner
  .column-2
  ul
  li:last-of-type {
  margin-bottom: 0;
}
header.mainHeader
  .megaMenu
  .row-2
  ul
  li.openFirstLevel
  .subMenu.firstLevel
  .inner
  .column-2
  ul
  li:last-of-type
  span::after {
  display: none;
}
header.mainHeader
  .megaMenu
  .row-2
  ul
  li.openFirstLevel
  .subMenu.firstLevel
  .inner
  .column-2
  ul
  li
  a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
header.mainHeader
  .megaMenu
  .row-2
  ul
  li.openFirstLevel
  .subMenu.firstLevel
  .inner
  .column-2
  ul
  li
  a:hover {
  color: #cc0000;
}
header.mainHeader
  .megaMenu
  .row-2
  ul
  li.openFirstLevel
  .subMenu.firstLevel
  .inner
  .column-2
  ul
  li
  a
  img {
  width: 100%;
  max-width: 90px;
  height: auto;
  display: block;
  border-radius: 50%;
  margin-right: 30px;
}
header.mainHeader
  .megaMenu
  .row-2
  ul
  li.openFirstLevel
  .subMenu.firstLevel
  .inner
  .column-2
  ul
  li
  a
  span {
  font-family: "Jost", sans-serif;
  font-weight: 500;
  font-size: 21px;
  line-height: 25px;
  text-transform: none;
  display: block;
  position: relative;
}
header.mainHeader
  .megaMenu
  .row-2
  ul
  li.openFirstLevel
  .subMenu.firstLevel
  .inner
  .column-2
  ul
  li
  a
  span::after {
  content: "";
  width: calc((100vw / 24) * 4);
  height: 1px;
  background: #00000040;
  position: absolute;
  left: calc((-100vw / 24) * 0.5);
  bottom: -35px;
}

.overlayMainMenu {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: #002244e6;
  z-index: 3;
  top: 0;
  left: 0;
  display: none;
}
@media only screen and (max-width: 1500px) {
  header.mainHeader
    .megaMenu
    .row-2
    ul
    li.openFirstLevel
    .subMenu.firstLevel
    .inner
    .column-1
    ul,
  header.mainHeader
    .megaMenu
    .row-2
    ul
    li.openFirstLevel
    .subMenu.firstLevel
    .inner
    .column-1
    ul
    li
    .subMenu.secondLevel
    ul {
    width: calc((100vw / 24) * 5);
  }
}
@media only screen and (max-width: 1300px) {
  header.mainHeader .megaMenu .row-2 ul li.openFirstLevel a {
    font-size: 12px;
  }
}
@media only screen and (max-width: 1150px) {
  header.mainHeader .megaMenu .row-2 ul li.openFirstLevel a,
  header.mainHeader .megaMenu .row-2 ul li.openFirstLevel button {
    font-size: 11px;
    line-height: 16px;
    padding-bottom: 0;
  }
  header.mainHeader .megaMenu .row-2 ul li.openFirstLevel .subMenu.firstLevel {
    padding-top: 20px;
    padding-bottom: 20px;
    top: 120px;
  }
  header.mainHeader
    .megaMenu
    .row-2
    ul
    li.openFirstLevel
    .subMenu.firstLevel
    .inner
    .column-1
    .top {
    margin-bottom: 10px;
  }
  header.mainHeader
    .megaMenu
    .row-2
    ul
    li.openFirstLevel
    .subMenu.firstLevel
    .inner
    .column-1
    .top
    a
    span {
    font-size: 11px;
    line-height: 16px;
  }
  header.mainHeader
    .megaMenu
    .row-2
    ul
    li.openFirstLevel
    .subMenu.firstLevel
    .inner
    .column-1
    .top
    p {
    font-size: 11px;
    line-height: 16px;
  }
  header.mainHeader
    .megaMenu
    .row-2
    ul
    li.openFirstLevel
    .subMenu.firstLevel
    .inner
    .column-1
    ul
    li
    a,
  header.mainHeader
    .megaMenu
    .row-2
    ul
    li.openFirstLevel
    .subMenu.firstLevel
    .inner
    .column-1
    ul
    li
    button {
    font-size: 11px;
    line-height: 16px;
  }
  header.mainHeader
    .megaMenu
    .row-2
    ul
    li.openFirstLevel
    .subMenu.firstLevel
    .inner
    .column-1
    ul
    li
    .subMenu.secondLevel {
    padding-top: 5px;
    margin-top: 20px;
  }
  header.mainHeader
    .megaMenu
    .row-2
    ul
    li.openFirstLevel
    .subMenu.firstLevel
    .inner
    .column-1
    ul
    li
    .subMenu.secondLevel
    ul
    li
    a,
  header.mainHeader
    .megaMenu
    .row-2
    ul
    li.openFirstLevel
    .subMenu.firstLevel
    .inner
    .column-1
    ul
    li
    .subMenu.secondLevel
    ul
    li
    button {
    font-size: 11px;
    line-height: 16px;
    padding: 5px 15px;
  }
  header.mainHeader
    .megaMenu
    .row-2
    ul
    li.openFirstLevel
    .subMenu.firstLevel
    .inner
    .column-2
    ul
    li
    a
    img {
    max-width: 50px;
  }
  header.mainHeader
    .megaMenu
    .row-2
    ul
    li.openFirstLevel
    .subMenu.firstLevel
    .inner
    .column-2
    ul
    li
    a
    span {
    font-size: 11px;
    line-height: 16px;
  }
  header.mainHeader
    .megaMenu
    .row-2
    ul
    li.openFirstLevel
    .subMenu.firstLevel
    .inner
    .column-1
    ul
    li
    a
    svg,
  header.mainHeader
    .megaMenu
    .row-2
    ul
    li.openFirstLevel
    .subMenu.firstLevel
    .inner
    .column-1
    ul
    li
    button
    svg {
    width: 8px;
  }
  header.mainHeader .megaMenu .row-2 ul li.openFirstLevel .subMenu.firstLevel,
  header.mainHeader
    .megaMenu
    .row-2
    ul
    li.openFirstLevel
    .subMenu.firstLevel
    .inner
    .column-1
    ul {
    min-height: auto;
  }
}
@media only screen and (max-width: 1100px) {
  header.mainHeader {
    padding: 40px 20px 10px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  header.mainHeader .logo {
    width: 100%;
  }
  header.mainHeader .row-1 {
    width: 100%;
    display: block;
    text-align: center;
    padding-right: 0;
    margin-top: 0;
    position: absolute;
    top: 10px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  header.mainHeader .row-1 ul.shortcuts {
    display: none;
  }
  header.mainHeader .megaMenu {
    padding: 0;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  header.mainHeader .megaMenu .row-2 {
    display: none;
  }
  header.mainHeader .megaMenu .mainNavMobile {
    display: none;
    background: #ffffff;
    border: 0;
    width: 100%;
    height: 90vh;
    margin: 0;
    height: calc(var(--vh, 1vh) * 100 - 90px);
    padding: 40px 20px 20px 20px;
    position: absolute;
    top: 110px;
    left: 0;
    z-index: 5;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
  }
  header.mainHeader .megaMenu .mainNavMobile ul.menuMobile li {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    text-transform: uppercase;
    color: #0f1e5a;
    display: block;
    padding-bottom: 20px;
  }
  header.mainHeader .megaMenu .mainNavMobile ul.menuMobile li:nth-child(4) {
    margin-bottom: 40px;
  }
  header.mainHeader .megaMenu .mainNavMobile ul.menuMobile li.back {
    padding-bottom: 50px;
  }
  header.mainHeader .megaMenu .mainNavMobile ul.menuMobile li.back svg {
    margin-left: 0;
    margin-right: 5px;
  }
  header.mainHeader .megaMenu .mainNavMobile ul.menuMobile li svg {
    margin-left: 5px;
  }
  header.mainHeader .megaMenu .mainNavMobile ul.menuMobile li button {
    background: none;
    border: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    text-transform: uppercase;
    color: #0f1e5a;
    display: block;
    padding: 3px;
  }
  header.mainHeader
    .megaMenu
    .mainNavMobile
    ul.menuMobile
    li
    .subMenu.firstLevel {
    display: none;
    z-index: 2;
    position: absolute;
    top: 0;
    left: 100%;
    width: 100%;
    padding-bottom: 30px;
  }
  header.mainHeader
    .megaMenu
    .mainNavMobile
    ul.menuMobile
    li
    .subMenu.firstLevel
    .inner
    ul
    li {
    text-transform: none;
    padding-bottom: 10px;
    text-align: left;
    padding-left: 0;
  }
  header.mainHeader
    .megaMenu
    .mainNavMobile
    ul.menuMobile
    li
    .subMenu.firstLevel
    .inner
    ul
    li:nth-child(4) {
    margin-bottom: 0;
  }
  header.mainHeader
    .megaMenu
    .mainNavMobile
    ul.menuMobile
    li
    .subMenu.firstLevel
    .inner
    ul
    li.back {
    text-transform: uppercase;
    padding-bottom: 20px;
  }
  header.mainHeader
    .megaMenu
    .mainNavMobile
    ul.menuMobile
    li
    .subMenu.firstLevel
    .inner
    ul
    li.back
    button {
    display: inline-block;
    background: none;
    border: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    text-transform: uppercase;
    color: #0f1e5a;
  }
  header.mainHeader
    .megaMenu
    .mainNavMobile
    ul.menuMobile
    li
    .subMenu.firstLevel
    .inner
    ul
    li
    a {
    color: #0f1e5a;
    font-weight: 400;
  }
  header.mainHeader
    .megaMenu
    .mainNavMobile
    ul.menuMobile
    li
    .subMenu.firstLevel
    .inner
    ul
    li
    button {
    font-family: "Jost", sans-serif;
    background: none;
    border: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    padding: 0;
    color: #0f1e5a;
    text-transform: none;
    text-align: left;
    padding-left: 0; /*{-webkit-appearance:none;border-radius:0*/
  }
  header.mainHeader
    .megaMenu
    .mainNavMobile
    ul.menuMobile
    li
    .subMenu.firstLevel
    .secondLevel {
    display: none;
    width: 100%;
    z-index: 3;
    position: absolute;
    top: 0;
    left: 100%;
  }
  header.mainHeader .megaMenu .mainNavMobile .row-1 {
    top: auto;
    bottom: 30px;
    padding: 0 20px;
  }
  header.mainHeader .megaMenu .mainNavMobile .row-1 ul.shortcuts {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  header.mainHeader .megaMenu .mainNavMobile .row-1 ul.shortcuts li {
    margin-left: 5px;
  }
  header.mainHeader
    .megaMenu
    .mainNavMobile
    .row-1
    ul.shortcuts
    li:first-of-type {
    margin-left: 0;
  }
  header.mainHeader
    .megaMenu
    .mainNavMobile
    .row-1
    ul.shortcuts
    li.switchLang
    .langList {
    right: auto;
    left: 65px;
  }
  header.mainHeader
    .megaMenu
    .mainNavMobile
    .row-1
    ul.shortcuts
    li.switchLang
    .langList
    li {
    margin-left: 5px;
  }
  header.mainHeader #burgerMenu {
    display: block;
    background: none;
    border: none;
    position: absolute;
    left: 20px;
    bottom: -80px;
  }
  header.mainHeader .logo {
    margin-bottom: -15px;
  }
  header.mainHeader .logo svg {
    padding: 0;
  }
  header.mainHeader .searchBtnMobile {
    display: block;
    background: none;
    border: 0;
    padding: 0;
  }
  header.mainHeader .searchBtnMobile svg {
    margin-bottom: -2px;
  }
  header.mainHeader .searchPopin {
    display: none;
    background: #ffffff;
    border: 0;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 40px 20px 20px 20px;
    position: absolute;
    top: 120px;
    left: 0;
    z-index: 5;
    overflow: scroll;
  }
  header.mainHeader .searchPopin form {
    position: relative;
  }
  header.mainHeader .searchPopin form input {
    width: 100%;
    border-radius: 35px;
    position: relative;
    z-index: 1;
    background: #ffffff;
    border: 1px solid #00000040;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    padding: 10px;
    overflow: hidden;
    font-weight: 400;
  }
  header.mainHeader .searchPopin form button {
    background: none;
    border: none;
    position: absolute;
    top: 50%;
    right: 10px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
  }
}
@media only screen and (max-width: 400px) {
  header.mainHeader .logo {
    margin-bottom: -7px;
  }
  header.mainHeader .logo svg {
    width: 100px;
  }
  header.mainHeader .megaMenu .mainNavMobile {
    top: 90px;
  }
  header.mainHeader .searchPopin {
    top: 90px;
  }
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex.dirc {
  flex-direction: column;
}

.flec.dirr {
  flex-direction: row;
}

.app.flex {
  flex-direction: column;
  text-align: center;
}

.app {
  background-color: gainsboro;
  font-family: "Jost", sans-serif;
}

.header {
  width: 1200px;
}

.header1 {
  display: flex;
  justify-content: center;
}

.header2 {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.container.flex {
  flex-direction: column;
  text-align: center;
}

.container.flex.row {
  flex-direction: row;
  text-align: center;
}

.container.tools {
  margin: 20px;
  background-color: #0f1e5a;
  color: white;
}

.container.admin {
  background-color: gainsboro;
  font-family: "Jost", sans-serif;
}

.card h4,
th h4 {
  color: white;
}

td h4 {
  color: #000000d9;
  margin-top: 0.5em;
}

.card1 {
  width: 1100px;
  height: auto;
  margin: 4px;
  background-color: white;
}

.card1.flex {
  flex-direction: column;
  text-align: center;
}

.card1.auto {
  width: auto;
}

.card01.flex.row {
  flex-direction: row;
  justify-content: center;
}

.card01 {
  border: solid 1px #0f1e5a;
  background-color: #0f1e5a;
  margin: 10px;
}

.card01 h4 {
  color: white;
  text-align: center;
  margin-top: 0.5em;
}

.card11 {
  padding: 20px;
  background-color: white;
}

.card2 {
  width: 1100px;
  margin: 4px;
  padding: 10px;
  background-color: white;
}

.card2.flex {
  flex-direction: row;
  text-align: center;
}

.card2.flex.border1 {
  background-color: whitesmoke;
}

.card2 th {
  background-color: white;
}

.c h4 {
  color: #282c34;
}
.padtop {
  padding-top: 10px;
}
.card3 {
  margin: 10px, 5px;
  padding-top: 4px;
}

.card3 h4 {
  /* color: #0f1e5a; */
}

.card4 {
  flex-direction: column;
  text-align: center;
}

th,
td {
  width: 200px;
  padding-right: 4px;
  padding-left: 4px;
}

th {
  color: white;
}

th h3 {
  color: rgb(161, 161, 161);
}

td h3 {
  color: rgb(190, 190, 190);
}

#updateInput {
  width: 100px;
  height: 20px;
  margin-bottom: 10px;
}

.mb {
  margin-bottom: 24px;
}

.bg {
  background-color: blanchedalmond;
}

.border1 {
  border-top: 1px Solid grey;
}

.index {
  margin-top: 30px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Mobile */
@media (max-width: 500px) {
  header.mainHeader {
    flex-direction: column;
    align-items: center;
    position: relative;
    height: 40em;
  }

  header.mainHeader .megaMenu {
    margin-top: 450px;
    position: relative;
  }

  header.mainHeader .megaMenu .row-2 {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0rem;
    padding: 0rem;
  }

  header.mainHeader {
    padding: 10px 10px 0px;
  }

  header.mainHeader .megaMenu .row-2 ul {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  header.mainHeader .megaMenu .row-2 ul li.openFirstLevel {
    padding: 0rem;
  }

  header.mainHeader .megaMenu {
    width: calc((100vw / 24) * 18);
    /* padding-right:calc((100vw / 24) * 0.5); */
    margin: 5px 0;
  }
  img {
    display: none;
  }

  .card2.flex {
    flex-direction: column;
    max-width: 24rem;
  }

  .card01 {
    max-width: 24rem;
  }

  .card2 {
    width: 14rem;
  }
  .card1.flex {
    flex-direction: column;
    text-align: center;
    max-width: 24rem;
  }
  main.main {
    margin-top: 300px;
  }
  header.mainHeader .megaMenu .row-2 .mainLinks {
    display: flex;
    flex-direction: column;
  }

  header.mainHeader .megaMenu .row-2 ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
